import { FAQ_DOC_URL, ONBOARDING_DOC_URL } from '../../../constants.platform';
import {
  MobileOnboardingStepOrder,
  OnboardingStep,
  OnboardingStepOrder,
} from '../../../types';
import nowTimestamp from '../../../utils/dates/nowTimestamp';
import {
  updateState,
} from '../../models';
import { updateDocuments } from './documents/update';


export const updateOnboardingStep = async (newOnboardingStep: OnboardingStep): Promise<void> => {
  await updateState((state) => {
    if (!state.persistent) {
      return;
    }
    const currentStep = state.persistent.onboardingStep;
    if (!currentStep || OnboardingStepOrder.indexOf(newOnboardingStep) >= OnboardingStepOrder.indexOf(currentStep)) {
      state.persistent.onboardingStep = newOnboardingStep;
      if (newOnboardingStep === OnboardingStep.Ready) {
        state.persistent.onboardedAt = nowTimestamp();
      }
    }
  }, { eventName: 'onboarding-step-updated', isUndoable: false, userInteraction: null });
};

export const updateMobileOnboardinStep = async (newOnboardingStep: OnboardingStep): Promise<void> => {
  await updateState((state) => {
    if (!state.persistent) {
      return;
    }
    const currentStep = state.persistent.mobileOnboardingStep;
    if (!currentStep || MobileOnboardingStepOrder.indexOf(newOnboardingStep) >= MobileOnboardingStepOrder.indexOf(currentStep)) {
      state.persistent.mobileOnboardingStep = newOnboardingStep;
      if (newOnboardingStep === OnboardingStep.Ready) {
        state.persistent.onboardedAt = nowTimestamp();
      }
    }
  }, { eventName: 'mobile-onboarding-step-updated', isUndoable: false, userInteraction: null });
};

export const resetOnboardingState = async () => {
  await updateState((state) => {
    const profile = state.client.profile;

    if (!state.persistent || !profile || !profile.is_staff) {
      return;
    }
    state.persistent.onboardingStep = OnboardingStep.Welcome;
    state.persistent.mobileOnboardingStep = OnboardingStep.Welcome;
    state.persistent.onboardedAt = null;
  }, { eventName: 'reset-onboarding-state', isUndoable: false, userInteraction: null });
};

export const bumpHelpDocDatesToNow = async () => {
  const now = nowTimestamp();
  updateDocuments({
    selector: {
      url: {
        $in: [ONBOARDING_DOC_URL, FAQ_DOC_URL],
      },
    },
  }, (doc) => {
    doc.saved_at = now;
    doc.last_status_update = now;
  }, { eventName: 'bump-help-docs-in-library', userInteraction: 'unknown', isUndoable: false });
};
